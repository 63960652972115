import React, { createContext, useContext, useEffect, useState } from "react";
import { getUserInfo, getUsers } from "../services/usersService";

import { ROLES } from "../config/constants";
import { getTenants } from "../services/tenantService";
import { useSessionContext } from "supertokens-auth-react/recipe/session"

// Create a Context for the data
const DataContext = createContext();

export const DataProvider = React.memo(({ children }) => {
  const session = useSessionContext();
  const [tenants, setTenants] = useState([]);
  const [users, setUsers] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!userInfo && session) {  // Only fetch if userInfo is not already set
      const fetchData = async () => {
        try {
          const tenantsData = await getTenants();
          setTenants(tenantsData.tenants);
  
          const allUsers = await Promise.all(
            tenantsData.tenants.map(async (tenant) => {
              const usersData = await getUsers(tenant.tenantId);
              return usersData.users.map((user) => ({
                ...user,
                tenantId: tenant.tenantId,
              }));
            })
          );
  
          const usersData = await getUserInfo();
          setUserInfo(usersData);
          setUsers(allUsers.flat());
          setLoading(false);
        } catch (err) {
          setError("Failed to fetch data.");
          setLoading(false);
        }
      };
  
      fetchData();
    } else {
      setLoading(false);
    }
  }, [userInfo, session]);

  const isSuperAdmin = userInfo?.rolesPerTenant[0]?.roles.some((role) => role.role === ROLES.SUPERADMIN);

  return (
    <DataContext.Provider value={{ tenants, users, userInfo, isSuperAdmin, loading, error }}>
      {children}
    </DataContext.Provider>
  );
});

// Custom hook to use the data context
export const useData = () => useContext(DataContext);
