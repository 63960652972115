import "./App.css";
import "./scss/style.scss";

import { ComponentWrapper, PreBuiltUIList, SuperTokensConfig } from "./config";
import { Provider, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";

import { DataProvider } from "./providers/DataProvider";
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import store from "./store.js";
import { useColorModes } from "@coreui/react";

SuperTokens.init(SuperTokensConfig);

const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

function App() {
  const { isColorModeSet, setColorMode } = useColorModes(
    "coreui-free-react-admin-template-theme"
  );
  const [storedTheme, setStoredTheme] = React.useState("light");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
    const theme =
      urlParams.get("theme") &&
      urlParams!.get("theme")!.match(/^[A-Za-z0-9\s]+/)![0];
    if (theme) {
      setColorMode(theme);
    }

    const themeFromStore = store.getState().theme; // Access Redux store directly
    if (themeFromStore) {
      setStoredTheme(themeFromStore);
    }

    if (isColorModeSet()) {
      return;
    }

    setColorMode(storedTheme);
  }, [isColorModeSet, setColorMode, storedTheme]);

  return (
    <Provider store={store}>
      <SuperTokensWrapper>
        <ComponentWrapper>
          <div className="App app-container">
            <Router>
              <div className="fill">
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Routes>
                    {/* This shows the login UI on "/auth" route */}
                    {getSuperTokensRoutesForReactRouterDom(
                      require("react-router-dom"),
                      PreBuiltUIList
                    )}

                    <Route
                      path="/*"
                      element={
                        /* This protects the "/" route so that it shows <Home /> only if the user is logged in. Else it redirects the user to "/auth" */
                        <SessionAuth>
                          <DataProvider>
                            <DefaultLayout />
                          </DataProvider>
                        </SessionAuth>
                      }
                    />
                    <Route path="/404" element={<Page404 />} />
                    <Route path="/500" element={<Page500 />} />
                    <Route path="*" element={<Page404 />} />
                  </Routes>
                </React.Suspense>
              </div>
            </Router>
          </div>
        </ComponentWrapper>
      </SuperTokensWrapper>
    </Provider>
  );
}

export default App;
