import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

// Fetch tenants
export const getTenants = async () => {
    console.log('tenants', API_URL)
    try {
        const response = await axios.get(`${API_URL}/tenants`, {
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching tenants", error);
        throw error;
    }
};
